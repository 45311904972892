<template>
    <div class="w-100">
        <section class="header-main-sec">
            <div class="header-inner-sec">
                <div class="logo_wpr"></div>
                <div class="breadcrumb-links"></div>
                <div class="header-right-menu">
                    <ul>
                        <li class="user-dropdown-area" v-click-outside="closeDropdown">
                            <div @click="toggleDropdown" class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <img :src="user.profile_pic ? user.profile_pic : require('@/assets/images/table-user.png')" class="avatar">
                            </div>
                            <div class="header-dropdown-content" v-if="active">
                                <div class="user_box">
                                    <img :src="user.profile_pic" class="avatar">
                                    <div class="user_info">
                                        <h4>
                                            <template v-if="user.company_id && user.is_company_location && accessed">
                                                {{ user.businessname }}
                                            </template>
                                            <template v-else>
                                                {{ user.full_name }}
                                            </template>
                                        </h4>
                                        <span>
                                            {{ companyLocations.user_type }}
                                        </span>
                                    </div>
                                </div>
                                <ul class="other_options">
                                    <template v-if="companyUserCan('view', 'admin-settings')">
                                        <li><a @click="logoutUser" class="logout"><i class="fas fa-sign-out-alt"></i>Logout</a></li>
                                    </template>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <div class="sign">
            <div class="left_area">
                <div class="logo">
                    <img :src="env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo2.svg') : require('@/assets/images/logo2.png')">
                </div>
                <h2 class="mb-2 text-center">Your Account Is In Hibernation</h2>
                <div v-if="user.company_id">
                    <p>You can not access your account because the subscription has been hibernated by the primary location. To resume it, please get in touch with them.</p>
                </div>
                <div class="plan-container mt-3" v-else-if="oldSubscription.subscription">
                    <div class="header">
                        <h4>Subscription</h4>
                    </div>
                    <div class="plan-details">
                        <p class="capitalize">{{ oldSubscription.subscription.name }}</p>
                        <p class="capitalize">${{ parseFloat(oldSubscription.subscription.price).toFixed(2) }}/{{ oldSubscription.subscription.interval }}</p>
                    </div>
                    <div class="header mt-3" v-if="oldSubscription.addons.length">
                        <h4>Addons</h4>
                    </div>
                    <div class="plan-details" v-for="(addon, a) of oldSubscription.addons" :key="a">
                        <p class="capitalize" v-if="addon.name =='contact'">{{ addon.name }} ({{addon.quantity * 100}} x ${{ parseFloat(addon.price).toFixed(3) }})</p>
                        <p class="capitalize" v-else>{{ addon.name }} ({{addon.quantity}} x ${{ parseFloat(addon.price).toFixed(2) }})</p>
                        <p class="capitalize">${{ parseFloat((addon.name == 'contact' ? addon.quantity * 100 : addon.quantity) * addon.price).toFixed(2) }}/{{ addon.interval }}</p>
                    </div>
                </div>
                <button v-if="!user.company_id" type="submit" class="primary_btn mt-5 pointer" :disabled="activateLoader" @click="handleActivateAccount()">
                    <span v-if="activateLoader"><i class="fa fa-spin fa-spinner"></i> &nbsp; Activating</span>
                    <span v-else>Activate Account</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: "Activate Account",

        data () {
            return {
                env: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                activateLoader: false,
                active: false,
            };
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                companyLocations: state => state.authModule.companyLocations,
                oldSubscription: state => state.billingModule.oldSubscription,
                oldSubscriptionLoader: state => state.billingModule.oldSubscriptionLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            vm.env = process.env;

            setTimeout(function () {
                const gist = window.gist;

                if (gist && process.env.VUE_APP_ENV == 'production') {
                    gist.chat('shutdown');
                }
            }, 500);

            vm.refreshAuth().then((resp) => {
                vm.getOldSubscriptions();
            });
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                logoutUser: 'authModule/logout',
                getOldSubscriptions: 'billingModule/getOldSubscriptions',
            }),

            handleActivateAccount () {
                const vm = this;
                const params = vm.oldSubscription;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to activate your account`, 'Yes');
                      options.preConfirm = () => { return vm.activateAccount(params) };

                Swal.fire(options);
            },

            activateAccount (params) {
                const vm = this;

                return new Promise(function(resolve, reject) {
                    axios.post(`${vm.billingApi}/stripe/subscription/resume`, params, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        Toastr.success(`Accoount has been activated successfully!`);
                        vm.refreshAuth();

                        resolve(true);
                    }).catch((err) => {
                        Toastr.error(err.response.data.error);
                        resolve(true);
                    });
                });
            },

            toggleDropdown () {
                const vm = this;

                vm.active = !vm.active;
            },

            closeDropdown () {
                const vm = this;

                vm.active = false;
            },
        },
    }
</script>

<style scoped>
    .sign {
        max-width: 640px;
    }

    .sign .left_area {
        width: 100%;
    }

    .plan-container {
        display: block;
    }

    .plan-container h4 {
        display: block;
        color: #121525;
    }

    .plan-details {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .plan-details p {
        margin: 0px;
    }
</style>
